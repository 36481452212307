<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template v-if="internetConnection">
            <LoaderPage v-if="isLoading"/>
            <template v-else>
                <!-- Search and sort membership data -->
                <div class="uk-flex uk-flex-between uk-margin">
                    <div class="uk-flex uk-flex-left">
                        <form @submit.prevent="filterSearch" class="uk-margin-remove">
                            <div class="uk-inline">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                                <input 
                                    class="uk-input" 
                                    type="search" 
                                    aria-label="Search member" 
                                    placeholder="Search member"
                                    v-model="meta.fullname"
                                />
                            </div>
                        </form>
                        <div class="uk-margin-left">
                            <form @change.prevent="filterSearch">
                            <select class="uk-select" v-model="meta.sortId">
                                <option :value="null">Sort</option>
                                <option value="descending">Newest</option>
                                <option value="ascending">Oldest</option>
                            </select>
                        </form>
                        </div>
                    </div>
                    <div class="uk-flex uk-flex-right">
                        <button v-if="consist(user_cred.roles, ['qwe123', 'admin-operation'])"
                            type="button" class="uk-button uk-button-primary uk-width-1-1 uk-padding-remove-vertical">
                            <img :src="`${images}/outline-upload.svg`" style="width:18px" /> Premium via Disburse
                        </button>
                        <div uk-dropdown="mode: click">
                            <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                <template>
                                    <li><a @click="() => { showDownloadModal = true }">Download Template</a></li>
                                    <li class="uk-nav-divider"></li>
                                    <li><a @click="() => { showUploadModal = true }">Upload Data</a></li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- End search and sort member -->

                <!-- Membership content list -->
                <div class="uk-margin-top uk-card uk-card-default">
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-striped uk-table-middle">
                            <thead>
                                <tr>
                                    <th class="uk-text-center uk-text-nowrap">No.</th>
                                    <th class="uk-text-center uk-text-nowrap">User</th>
                                    <th class="uk-text-center uk-text-nowrap">Membership</th>
                                    <th class="uk-text-center uk-text-nowrap">Roles Type</th>
                                    <th class="uk-text-center uk-text-nowrap">Email</th>
                                    <th class="uk-text-center uk-text-wrap">Telephone Number</th>
                                    <th class="uk-text-center uk-text-nowrap">Latest Payment Method</th>
                                    <th class="uk-text-center uk-text-nowrap uk-width-small">Action</th>
                                </tr>
                            </thead>
                            <loading-table v-if="tableLoading" :colspan="8" />
                            <template v-else>
                                <tbody v-if="membershipData.totalDocs > 0">
                                    <tr v-for="(user, index) in membershipData.docs" :key="index">
                                        <td class="uk-text-center uk-text-nowrap">
                                            {{ autoIncrementNumberPagination({ 
                                                pagingCounter: membershipData.pagingCounter,
                                                index  
                                            }) }}
                                        </td>
                                        <td class="uk-text-left uk-text-nowrap">
                                            <div class="uk-flex uk-flex-middle uk-flex-left">
                                                <img
                                                    v-if="user && user.photo_url && user.photo_url.file_url"
                                                    :src="user.photo_url.file_url" 
                                                    class="user-profile-picture" 
                                                    alt="Profile picture"
                                                    uk-img
                                                />
                                                <img
                                                    v-else
                                                    :src="`${images}/avatar.svg`" 
                                                    class="user-profile-picture" 
                                                    alt="Profile picture"
                                                    uk-img
                                                />
                                                <p class="uk-margin-left">{{ user && user.fullname || '-' }}</p>
                                            </div>
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            {{ user && user.is_vip ? 'Premium' : 'Free' }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            {{ user && user.role_type || '-' }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            {{ user && user.email || '-' }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            {{ user && user.phone_number || '-' }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            {{ user && user.membership && user.membership.payment_method || '-' }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            <button
                                                style="width:100px; cursor:pointer; margin-bottom:5px; height:30px; background:#333747"
                                                class="uk-button-primary"
                                                v-clipboard:copy="user && user._id || null"
                                                v-clipboard:success="onCopy"
                                            >Copy ID</button>
                                            <br/>
                                            <button 
                                                class="uk-button-default uk-margin-small-top" 
                                                style="width: 100px; cursor: pointer; height: 30px;"
                                                @click="membershipDetail({ user_id: user && user._id || null })"
                                            >Detail</button>
                                            <br/>
                                        </td>
                                    </tr>
                                </tbody>
                                <empty-table v-else :colspan="8" />
                            </template>
                        </table>
                    </div>
                    <pagination
                        :total-data="membershipData.totalDocs"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                    />
                </div>
                <!-- End membership content list -->
            </template>

            <UploadModal v-if="showUploadModal" @hideModal="hideUploadModal" />
            <DownloadModal v-if="showDownloadModal" @hideModal="hideDownloadModal" />
        </template>
        <NoInternetConnection v-else />
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from '@/utils/formatter';

export default {
    name: 'Membership',
    components: {
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
        UploadModal: () => import('./upload-template'),
        DownloadModal: () => import('./download-template'),
    },
    data(){
        return {
            internetConnection: true,
            isLoading: false,
            tableLoading: false,
            membershipData: {
                docs: [],
                pagingCounter: 0,
                totalDocs: 0
            },
            meta: {
                limit: 10,
                page: 1,
                fullname: '',
                sortId : null,
            },
            images: PREFIX_IMAGE,
            user_cred: getUserLogin(),
            showDownloadModal: false,
            showUploadModal: false,
        };
    },
    async mounted(){
        try {
            if (!navigator.onLine) return this.internetConnection = false;
            this.isLoading = true;
            await this.setMembershipData();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    watch: {
        async meta() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                await this.setMembershipData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
    },
    methods: {
        ...mapActions({
            getMembershipList: 'membershipV4/getMembershipList'
        }),
        async setMembershipData(){
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                const response = await this.getMembershipList(this.meta);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result;
                    const docs = result && Array.isArray(result.docs) && result.docs.length > 0 ? result.docs : [];
                    const pagingCounter = result && result.pagingCounter || 0;
                    const totalDocs = result && result.totalDocs || 0;

                    this.membershipData.docs = docs;
                    this.membershipData.pagingCounter = pagingCounter;
                    this.membershipData.totalDocs = totalDocs;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async filterSearch(){
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                this.meta.limit = 10;
                this.meta.page = 1;
                await this.setMembershipData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        changeLimit(e) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        onCopy(e) {
            if (!navigator.onLine) return this.internetConnection = false;
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        membershipDetail({ user_id }){
            if (!navigator.onLine) return this.internetConnection = false;
            this.$router.push(`/admin/membership/detail/${user_id}`);
        },
        consist(user_roles, value) {
            return value.some(i => user_roles.includes(i));
        },
        hideUploadModal() {
            this.showUploadModal = false;
        },
        hideDownloadModal() {
            this.showDownloadModal = false;
        },
        autoIncrementNumberPagination({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({ pagingCounter, index });
        }
    }
};
</script>

<style scoped>
.user-profile-picture {
    width: 100%;
    height: 100%;
    min-width: 44px;
    max-width: 44px;
    min-height: 44px;
    max-height: 44px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}
</style>
